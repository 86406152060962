var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-table",
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Status")
              ]),
              _c(
                "gov-table-cell",
                [
                  _c(
                    "gov-tag",
                    {
                      class:
                        "notification-status notification-status--" +
                        _vm.formatStatus(_vm.notification)
                    },
                    [_vm._v(_vm._s(_vm.formatStatus(_vm.notification)))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Channel")
              ]),
              _c("gov-table-cell", [
                _vm._v(_vm._s(_vm.formatChannel(_vm.notification.channel)))
              ])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Type")
              ]),
              _c("gov-table-cell", [
                _vm._v(_vm._s(_vm.formatType(_vm.notification.notifiable_type)))
              ])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Recipient")
              ]),
              _c("gov-table-cell", [_vm._v(_vm._s(_vm.notification.recipient))])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Date / Time")
              ]),
              _c("gov-table-cell", [
                _vm._v(_vm._s(_vm.formatSentAt(_vm.notification)))
              ])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Message")
              ]),
              _c(
                "gov-table-cell",
                _vm._l(_vm.notification.message.split("\n"), function(
                  paragraph,
                  index
                ) {
                  return _c("gov-body", {
                    key: index,
                    domProps: { textContent: _vm._s(paragraph) }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }